import React, { Component } from "react";

export default class ConsultaPopular extends Component {
  render() {
    return (
      <>
        <h1 className="text-center mb-4 mt-3">CONSULTA POPULAR</h1>
        <p>
          Proceso permanente que los órganos de gobierno procurarán desahogar,
          para recoger y conocer la opinión y las propuestas de los diversos
          sectores de la población, que contribuyan a hacer realidad el proyecto
          social contenido en la Constitución Local y en los planes y programas
          de los gobiernos estatal y municipales y en el Programa Legislativo
          del Congreso. La consulta popular se podrá realizar, por medio de
          cuestionarios dirigidos a quienes corresponda según la materia sujeta
          a consulta; de encuestas generales o segmentadas; de sondeos de
          opinión y entrevistas; de foros, seminarios y reuniones públicas; y de
          otros medios e instrumentos que sean eficaces y propicien la
          participación social a fin de recopilar la opinión y las propuestas de
          los/as ciudanos/as. El procedimiento de consulta popular se iniciará
          mediante convocatoria pública que emita el órgano de gobierno de que
          se trate, la cual contendrá por lo menos, los requisitos siguientes:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>
            Los motivos y fundamentos por los cuales se realiza la consulta;
          </li>
          <li>
            El objeto de la consulta y el período durante el cual, en su caso,
            se ejecutará el acto que resulte, por la autoridad administrativa;
          </li>
          <li>Los medios y la metodología a utilizar en la consulta popular</li>
          <li>Lugar, fecha o periodo y horario en que se llevará a cabo,</li>
          <li>Órgano u órganos de gobierno que la emiten.</li>
        </ol>

        <p>
          La convocatoria a consulta popular se emitirá a más tardar 15 días
          naturales anteriores a la fecha en que deba realizarse la consulta, y
          se difundirá ampliamente por los medios al alcance. Si el tema o
          asunto no fue objeto de otra consulta, durante los últimos 12 meses
          inmediatos anteriores, la consulta popular solo será convocada por el
          órgano de gobierno que de acuerdo a la materia tenga competencia para
          llevarla a cabo. Durante el periodo de campañas electorales y hasta la
          conclusión del proceso electoral respectivo, no se podrá celebrar
          consulta popular alguna.
        </p>
        <div className="mx-auto w-75">
          <a
            href="https://participacion-ciudadana.itetlax.org.mx/assets/img/4.jpg"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/4.jpg"
              className="img-fluid w-100"
              alt="Procedimiento para realizar una consulta popular"
            />
          </a>
        </div>
      </>
    );
  }
}
