import React, { Component } from "react";

export default class Referendum extends Component {
  render() {
    return (
      <>
        <h1 className="text-center mb-4 mt-3">REFERÉNDUM</h1>
        <p>
          El referéndum tiene como fin que los/as ciudadanos/as manifiesten su
          aprobación o rechazo respecto de los actos siguientes:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>
            Las modificaciones, reformas, adiciones o derogaciones a la
            Constitución Local;
          </li>
          <li>
            La creación, modificación, reformas, adición, derogación o
            abrogación de las leyes o decretos que expida el Congreso;
          </li>
          <li>
            La creación, modificación, reformas, adición, derogación o
            abrogación de los reglamentos del Municipio.
          </li>
        </ol>
        <p>El referéndum podrá ser:</p>
        <ul className="ms-5">
          <li>Constitucional</li>
          <li>Legislativo</li>
          <li>Reglamentario o Municipal</li>
          <li>Constitutivo;</li>
          <li>Abrogatorio</li>
        </ul>
        <p>
          No podrán ser materia de referéndum las leyes, reglamentos y decretos
          que versen sobre las materias siguientes:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>Tributaria o fiscal, así como el de Egresos del Estado;</li>
          <li>Control constitucional;</li>
          <li>Actos jurisdiccionales o de procedimientos administrativos;</li>
          <li>
            Régimen interno de la administración pública del Estado o de los
            municipios;
          </li>
          <li>Regulación interna del Congreso;</li>
          <li>Régimen interno de los organismos públicos autónomos;</li>
          <li>Regulación interna del Poder Judicial del Estado;</li>
          <li>
            Leyes, decretos o reglamentos que hayan sido objeto de referéndum o
            plebiscito durante tres años anteriores inmediatos, y
          </li>
          <li>Las demás que determinen las leyes.</li>
        </ol>
        <p>El referéndum lo pueden solicitar:</p>
        <ol type="I" start="1" className="ms-5">
          <li>
            Por lo menos diez por ciento de los/as ciudadanos/as inscritos/as en
            el registro federal de electores/as. correspondiente a la
            circunscripción del Estado, cuando se trate de reformas o adiciones
            a la Constitución Local;
          </li>
          <li>
            Por lo menos cinco por ciento de los/as ciudadanos/as inscritos/as
            en el registro federal de electores/as. correspondiente a la
            circunscripción del Estado, cuando se trate de leyes, reglamentos o
            decretos, dentro del término de 40 días naturales siguientes a su
            vigencia, y
          </li>
          <li>
            Por lo menos cinco por ciento de los/as ciudadanos/as inscritos/as
            en el registro federal de electores/as. correspondiente a la
            circunscripción del Municipio, cuando se trate de reglamentos y
            normas municipales.
          </li>
        </ol>
        <p>
          Los resultados del referéndum legislativo y municipal serán
          vinculatorios cuando:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>
            En el ámbito estatal hayan votado al menos el treinta por ciento de
            los/as ciudadanos/as inscritos en el registro federal de
            electores/as. correspondiente a la circunscripción del Estado, y de
            éstos, que más del cincuenta por ciento haya emitido su voto en el
            mismo sentido, y
          </li>
          <li>
            En el ámbito municipal, al menos el 30% de los/as ciudadanos/as
            inscritos/as en el registro federal de electores/as./as
            correspondiente a la circunscripción del Municipio de que se trate,
            y de éstos/as que más del 50% haya emitido su voto en el mismo
            sentido. Cuando el resultado del referéndum no alcance los
            porcentajes requeridos para ser vinculatorio tendrá efectos
            meramente indicativos. Si el resultado del referéndum legislativo
            realizado en la Entidad es en el sentido de desaprobar la ley o
            decreto, el Congreso emitirá el decreto abrogatorio o derogatorio
            correspondiente, lo que deberá realizar en un término no mayor de 15
            días, si se encuentra en período ordinario, y si se encuentra en
            receso, deberá hacerlo en la segunda sesión del período inmediato,
            decisión que deberá ser del conocimiento del Pleno
          </li>
        </ol>
        <p>
          Si el resultado del referéndum reglamentario realizado en el Municipio
          es en el sentido de desaprobar en todo o en parte, el reglamento o
          disposición de carácter general, corresponderá al Ayuntamiento emitir
          acuerdo abrogatorio o derogatorio, en un término no mayor de 15 días.
          Dentro de los dos años contados a partir de la publicación del decreto
          derogatorio o abrogatorio, resultado de un proceso de referéndum, el
          Congreso o los Ayuntamientos no podrán expedir decreto en el mismo
          sentido del abrogado o derogado. Los efectos de una ley, decreto,
          reglamento o disposición, que sean materia de referéndum, serán
          suspendidos cuando dicho mecanismo de consulta ciudadana sea
          solicitado por la mayoría simple de los/as diputados/as que se
          encuentren en sesión y cuando sea solicitado por la mayoría simple de
          los/as integrantes de un Ayuntamiento.
        </p>
        <div className="mx-auto w-75">
          <a
            href="https://participacion-ciudadana.itetlax.org.mx/assets/img/2.jpg"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/2.jpg"
              className="img-fluid w-100"
              alt="Procedimiento para realizar referéndum"
            />
          </a>
        </div>
      </>
    );
  }
}
