import React, { Component } from "react";

export default class VozCiudadana extends Component {
  render() {
    return (
      <>
        <h1 className="text-center mb-4 mt-3">VOZ CIUDADANA EN EL CABILDO</h1>
        <p>
          Es un medio de participación personal del/a ciudadano/a residente en
          el Municipio en una sesión de Cabildo. La solicitud del/la ciudadano/a
          de participar en una sesión de Cabildo, y el/la Secretario/a del
          Ayuntamiento al recibirla deberá evaluar si es competencia del
          Ayuntamiento o del Municipio atender dicha solicitud, conforme a las
          constituciones federal y estatal, así como a la Ley Municipal,
          valoración que deberá comunicarse por escrito al/la interesado/a.
          El/la ciudadano/na interesado/a en presentar una propuesta al Pleno de
          Cabildo presentará su solicitud de participar en la sesión de Cabildo
          próxima, indicando:
        </p>
        <ul className="ms-5">
          <li>Nombre completo;</li>
          <li>Domicilio;</li>
          <li>Teléfono;</li>
          <li>Clave de Elector.</li>
        </ul>
        <p>
          Además deberá anexar fotocopia de su credencial para votar con
          fotografía y copia de comprobante de pago predial y de agua. La
          solicitud se deberá acompañar de la propuesta, que contendrá:
        </p>
        <ol className="ms-5">
          <li>
            Propuesta o sugerencia en beneficio de una calle, manzana, sección,
            barrio, colonia o del Municipio en general y de sus habitantes;
          </li>
          <li>Objetivo y beneficios;</li>
          <li>Costo;</li>
          <li>
            Compromiso o disposición de participación, por parte de los
            habitantes, en la puesta en marcha de la propuesta o sugerencia, y
          </li>
          <li>Conclusión, petición o solicitud final al Cabildo.</li>
        </ol>
        <p>
          Si la propuesta en cuestión es competencia municipal, deberá
          integrarse al orden del día de la sesión de Cabildo más próxima. El
          Cabildo, después de escuchar la presentación de viva voz por parte
          del/la ciudadano/a promovente de la propuesta, podrá requerir mayor
          información sobre la misma, evaluando en la sesión de Cabildo
          inmediata posterior a su presentación, su factibilidad y el plazo de
          tiempo requerido para llevar a cabo la idea presentada. La resolución
          a la que llegue el Cabildo será comunicada por escrito al/la
          interesado/a y en caso de ser positiva la respuesta a su petición, se
          difundirá a los vecinos del Municipio, por los medios al alcance del
          Ayuntamiento, con el propósito de estimular la consulta ciudadana
          responsable, en beneficio de la comunidad.
        </p>
        <div className="mx-auto w-75">
          <a
            href="https://participacion-ciudadana.itetlax.org.mx/assets/img/1.jpg"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/1.jpg"
              className="img-fluid w-100"
              alt="Procedimiento para participar con Voz"
            />
          </a>
        </div>
      </>
    );
  }
}
