import React, { Component } from "react";

export default class Plebiscito extends Component {
  render() {
    return (
      <>
        <h1 className="text-center mb-4 mt-3">PLEBISCITO</h1>
        <p>
          El plebiscito es una forma de participación, a través de la cual se
          somete a consideración de los/as ciudadanos/as, para que expresen su
          aprobación o rechazo a los actos siguientes:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>Obras y servicios públicos;</li>
          <li>Actos o decisiones del Poder Ejecutivo;</li>
          <li>
            Actos, decisiones o propuestas de los gobiernos municipales o de los
            órganos que hagan tales funciones;
          </li>
          <li>
            Supresión, fusión, formación o conformación territorial de
            municipios, siempre que medie promoción del Poder Legislativo o del
            Ejecutivo;
          </li>
          <li>
            Cambio de régimen de elección de presidentes/as de comunidad,
            siempre que al respecto anteceda conflicto debido a tal
            circunstancia en la comunidad de que se trate y que medie acuerdo
            del Ayuntamiento para su promoción, y
          </li>
          <li>
            Los demás que propongan por lo menos el 25% de los/as ciudadanos/as
            de la demarcación político territorial inscritos en el Registro
            Federal de Electores, al último corte que proporcione el órgano
            federal competente, en términos de las constituciones federal y
            local así como las leyes aplicables.
          </li>
        </ol>
        <p>No serán materia de plebiscito:</p>
        <ol type="I" start="1" className="ms-5">
          <li>
            Disposiciones legales en materia de tributación, fiscales y egresos;
          </li>
          <li>Régimen interno de la Administración Pública del Estado;</li>
          <li>
            El nombramiento o destitución de los titulares de secretarías o
            dependencias del Ejecutivo o de los gobiernos municipales;
          </li>
          <li>Actos y decisiones objeto de control jurisdiccional;</li>
          <li>
            Actos cuya realización sea obligatoria, imposible o prohibida en los
            términos de ley;
          </li>
          <li>
            Actos o decisiones materia de convenios con otros poderes locales y
            federales, conforme a las constituciones políticas respectivas;
          </li>
          <li>
            Actos que alteren los principios fundamentales consagrados por la
            Constitución Política de los Estados Unidos Mexicanos, y VIII. Los
            demás que determinen las leyes.
          </li>
        </ol>

        <p>El Plebiscito podrá ser solicitado por:</p>
        <ol type="I" start="1" className="ms-5">
          <li>El titular del Poder Ejecutivo;</li>
          <li>
            El Congreso, cuando lo solicite el cincuenta por ciento más uno de
            sus integrantes;
          </li>
          <li>
            El Ayuntamiento, mediante el voto de la mayoría simple de sus
            integrantes;
          </li>
          <li>
            El 25% de los/as electores/as del Estado, inscritos en el registro
            federal de electores, en tratándose de actos o decisiones de las
            autoridades estatales;
          </li>
          <li>
            El 25% por ciento de los/as electores/as municipales inscritos en el
            registro federal de electores, en tratándose de actos o decisiones
            de las autoridades municipales, y
          </li>
          <li>
            El 25% de los/as electores/as municipales inscritos en el registro
            federal de electores, para solicitar la erección de un nuevo
            Municipio.
          </li>
        </ol>
        <p>
          Los efectos del acto o decisión materia de plebiscito, solo serán
          suspendidos cuando dicho mecanismo de consulta ciudadana, sea
          solicitada por el Titular del Ejecutivo o por los Ayuntamientos,
          suspensión que en su caso, durará hasta en tanto quede firme el
          acuerdo de validación que emita la Comisión. El resultado del
          plebiscito será vinculatorio cuando:
        </p>
        <ol type="I" start="1" className="ms-5">
          <li>
            En el ámbito estatal hayan votado al menos el treinta por ciento de
            los/as ciudadanos/as inscritos en el registro federal de electores
            correspondiente al Estado, y de éstos, más del cincuenta por ciento
            hayan emitido su voto en el mismo sentido;
          </li>
          <li>
            En el ámbito municipal hayan votado al menos treinta por ciento de
            los/as ciudadanos/as inscritos en el registro federal de electores
            correspondiente al Municipio, y de éstos, más del cincuenta por
            ciento hayan emitido su voto en el mismo sentido;
          </li>
          <li>
            Tratándose de la erección de un nuevo Municipio hayan aprobado tal
            petición, cuando menos las dos terceras partes de los/as
            ciudadanos/as que participen en el plebiscito y tengan residencia en
            el Municipio o Municipios involucrados, en términos de lo dispuesto
            por la Constitución Local, y
          </li>
          <li>
            Tratándose del cambio de régimen de elección de presidentes/as de
            comunidad, sea aprobado cuando menos por las dos terceras partes de
            los/as ciudadanos/as que participen en el plebiscito y tengan
            residencia en la Comunidad correspondiente. En todos los casos, de
            no alcanzarse la votación requerida para que el resultado del
            plebiscito sea vinculatorio, tendrá efectos meramente indicativos.
            Si el resultado del plebiscito es en el sentido de rechazar el acto
            o decisión materia del mismo, el Titular del Ejecutivo o en su caso
            del Ayuntamiento, emitirá decreto o acuerdo revocatorio en un
            término que no excederá de 20 días naturales. El Titular del
            Ejecutivo o en su caso, del Ayuntamiento, no podrán expedir decreto
            o acuerdo en el mismo sentido del abrogado o derogado dentro de los
            dos años siguientes, término que se computará a partir del día
            siguiente a aquél en que sea publicado el acuerdo derogatorio o
            abrogatorio correspondiente.
          </li>
        </ol>
        <div className="mx-auto w-75">
          <a
            href="https://participacion-ciudadana.itetlax.org.mx/assets/img/3.jpg"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/3.jpg"
              className="img-fluid w-100"
              alt="Procedimiento para realizar plebiscito"
            />
          </a>
        </div>
      </>
    );
  }
}
