import React, { Component } from "react";
import "./Header.css";

export default class Header extends Component {
  render() {
    return (
      <>
        <img
          className="img-fluid w-100"
          src="https://participacion-ciudadana.itetlax.org.mx/assets/img/header.jpg"
          alt="Participación Ciudadana"
        />
        <div className="row w-100 mt-5 mb-5">
          <div className="col-md-1"></div>
          <div className="col-md-1 d-flex justify-content-start align-items-center">
            <img
              className="img-fluid"
              src="https://participacion-ciudadana.itetlax.org.mx/assets/img/icons/1.png"
              alt="Estado de Tlaxcala"
            />
          </div>
          <div className="col-md-10 d-flex justify-content-start align-items-center">
            <h1 className="Header__title">P R E S E N T A C I Ó N</h1>
          </div>
        </div>
        <div className="w-75 mx-auto">
          <p>
            Presentamos a la ciudadanía este micrositio, como una herramienta
            cuyo propósito es brindar apoyo y orientación en el ejercicio de los
            derechos en materia de participación ciudadana.
          </p>
          <p>
            En este apartado encontraras el marco legal, descripciones,
            actividades, trípticos, infografías, videos, así como
            representaciones gráficas para el uso y tramites de los mecanismo de
            participación.
          </p>
          <p>
            Te invitamos a difundir y compartir los materiales que pondremos a
            tu disposición, para que la cultura de la participación y el
            ejercicio de estos derechos sean permanentes en nuestra entidad; con
            ello, haremos cada vez más presente una ineludible y cabal rendición
            de cuentas, además de incidir en las decisiones públicas de nuestra
            comunidad y de nuestro Estado.
          </p>
          <p>
            La Comisión de Participación Ciudadana y el Área Técnica de Consulta
            Ciudadana te brindara la asesoría correspondiente en cualquiera de
            los temas de tu interés en material de participación ciudadana.
          </p>

          <h4 className="text-center">Sean todas y todos bienvenidos</h4>
          <p className="text-center text-secondary fst-italic">
            INSTITUTO TLAXCALTECA DE ELECCIONES
            <br />
            Comisión de Participación Ciudadana
          </p>
        </div>
        <img
          className="img-fluid w-100"
          src="https://participacion-ciudadana.itetlax.org.mx/assets/img/header2.jpg"
          alt="Mecanismos de Participación Ciudadana"
        />
        <div className="bg-ite text-center text-white p-2">
          MECANISMO DE CONSULTA CIUDADANA
        </div>
      </>
    );
  }
}
