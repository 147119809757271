import React, { Component } from "react";
import ModalIMG from "../layout/ModalIMG";
import { dataInfoografias } from "../data/dataInfografias";

export default class Infografias extends Component {
  render() {
    return (
      <>
        <h1 className="text-center mb-4 mt-3">INFOGRAFÍAS</h1>
        <div className="Galeria__responsive">
          {dataInfoografias.map((img) => (
            <div key={img.id} className="Galeria__responsive-item">
              <a
                href="/#"
                rel="noreferrer"
                data-bs-toggle="modal"
                data-bs-target={"#Modal" + img.id}
              >
                <img
                  src={img.imgURL}
                  alt={img.alt}
                  className="img-fluid rounded"
                />
              </a>
              <ModalIMG
                idModal={"Modal" + img.id}
                altIMG={img.alt}
                linkIMG={img.imgURL}
              />
            </div>
          ))}
        </div>
        {}
      </>
    );
  }
}
