import React, { Component } from "react";

export default class Tripticos extends Component {
  render() {

    return <div>
    <h1 className="text-center mb-4 mt-3">Estudio de Participacion Ciudadana del Proceso Local Ordinario 2020-2021</h1>
    <object data="https://itetlax.org.mx/participacion-ciudadana/assets/pdf/1.pdf"

                            type="application/pdf" width="100%" height="800px">

                            <p>It appears you don't have a PDF plugin for this browser.

                                No biggie... you can <a

                                    href="./assets/4.2_Informe del procesamiento de acuerdos_Fase_Operativa_de_Acuerdos.pdf">click

                                    here to

                                    download the PDF file.</a></p>

                        </object>

    </div>;
  }
}
