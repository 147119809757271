export const dataInfoografias = [
  {
    id: 1,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/1.jpg",
    alt: "Mecanismos de Participacion Ciudadana",
  },
  {
    id: 2,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/2.jpg",
    alt: "Iniciativa Popular",
  },
  {
    id: 3,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/3.jpg",
    alt: "Consulta Popular",
  },
  {
    id: 4,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/4.jpg",
    alt: "Plebiscito",
  },
  {
    id: 5,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/5.jpg",
    alt: "Referéndum",
  },
  {
    id: 6,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/6.jpg",
    alt: "Voz Ciudadana en el Cabildo",
  },
  {
    id: 7,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/7.jpg",
    alt: "Estudio de participación ciudadana",
  },
  {
    id: 8,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/8.jpeg",
    alt: "Elecciones",
  },
  {
    id: 9,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/9.jpeg",
    alt: "Delito electoral",
  },
  {
    id: 10,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/10.jpeg",
    alt: "Votar",
  },
  {
    id: 11,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/11.jpeg",
    alt: "Votar 2",
  },
  {
    id: 12,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/12.jpeg",
    alt: "Denuncia",
  },
  {
    id: 13,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/13.jpeg",
    alt: "Tu voto",
  },
  {
    id: 14,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/14.jpeg",
    alt: "historia",
  },
  {
    id: 15,
    imgURL:
      "https://participacion-ciudadana.itetlax.org.mx/assets/img/infografias/15.jpeg",
    alt: "no olvides",
  },
];
